// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
	// eslint-disable-next-line no-restricted-globals
	self.addEventListener('message', (e) => {
		console.log(e)
		if (!e) return

		const users = []

		const userDetails = {
			name: 'Jane Doe',
			email: 'jane.doe@gmail.com',
			id: 1,
		}

		for (let i = 0; i < 10000000; i++) {
			userDetails.id = i++
			userDetails.dateJoined = Date.now()

			users.push(userDetails)
		}

		postMessage(users)
	})
}
