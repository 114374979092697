import { serverAddress as APIaddress } from './APIaddress'

// export const googleClientId = '948867192125-vqi1rvlgem6c9nk60nopajiogjf5uqra.apps.googleusercontent.com'
// export const googleClientIdForAdminLogin = '948867192125-vqi1rvlgem6c9nk60nopajiogjf5uqra.apps.googleusercontent.com'

export { googleClientId, googleClientIdForAdminLogin, adminEmailAddress, adminDomainAddress } from './APIaddress'

export const serverAddress = APIaddress

export const companyLoginAddress = serverAddress + 'companyauth/login'
export const companyLogoutAddress = serverAddress + 'companyauth/logout'
export const companyRegsiterAddress = serverAddress + 'companyauth/register'
export const companyForgotPassword = serverAddress + 'companyauth/forgotpassword'
export const companyResendVerificationMail = serverAddress + 'companyauth/resend_verification'
export const companyChangePassword = serverAddress + 'company/changepassword'
export const companyRefreshTokenAddress = serverAddress + 'companyauth/token'
export const companyValidateAccessTokenAddress = serverAddress + 'companyauth/jwttest'
export const companyCreateNewListing = serverAddress + 'company/form2'
export const companyCreateNewProfile = serverAddress + 'company/create_profile'
export const companyAllProfiles = serverAddress + 'company/profiles'
export const companyAllListings = serverAddress + 'company/listings'
export const companyBasicData = serverAddress + 'company/intro'
export const companyGetForm3Data = serverAddress + 'company/form_3'
export const companySetForm3Data = serverAddress + 'company/form3'
export const companyUploadShortlist = serverAddress + 'company/uploadshortlist/'
export const companyGetRegisteredStudents = serverAddress + 'company/registered'
export const companyGetRegisteredStudentsExcel = serverAddress + 'company/registeredexcel'
export const companyGetInterviewListExcel = serverAddress + 'company/interviewsheet/'
export const companyUploadSelectedExcel = serverAddress + 'company/uploadselected/'
export const companyUploadWailistExcel = serverAddress + 'company/uploadwaitlist/'
export const companyUploadEmptyWailistExcel = serverAddress + 'company/nowaitlist'
export const companyDownloadPlacedExcel = serverAddress + 'company/placedexcel/'
export const companyEditListing = serverAddress + 'company/edit_listing'
export const companyEditProfiles = serverAddress + 'company/edit_profiles'
export const companyRemoveProfile = serverAddress + 'company/remove_profile'
export const companyRemoveListing = serverAddress + 'company/remove_listing'
export const companyIsItPlacement = serverAddress + 'company/placement_detail'
export const companyJobDescriptionFileDownload = serverAddress + 'company/document/'
export const companyFeedback = serverAddress + 'company/company_feedback'

export const placementEditListing = serverAddress + 'placement/edit_listing'
export const placementEditProfiles = serverAddress + 'placement/edit_profiles'
export const placementRemoveProfile = serverAddress + 'placement/remove_profile'
export const placementRemoveListing = serverAddress + 'placement/remove_listing'
export const placementcompanyReport = serverAddress + 'placement/companyreport'
export const placementOpenRegistration = serverAddress + 'placement/openregistration'
export const placementCloseRegistration = serverAddress + 'placement/closeregistration'
export const placementSubmitRegistrations = serverAddress + 'placement/submitregistrations'
export const placementUpdateVenue = serverAddress + 'placement/updatevenue'
export const placementCoodDeadline = serverAddress + 'placement/listing_deadline'
export const placementAlertStudent = serverAddress + 'placement/alert_listing_deadline'
export const placementGetRegisteredStudents = serverAddress + 'placement/registered/'
export const placementGetShortlistStudents = serverAddress + 'placement/shortlist/'
export const placementcompanyUploadEmptyWailistExcel = serverAddress + 'placement/nowaitlist'
export const placementcompanyGetRegisteredStudentsExcel = serverAddress + 'placement/registeredexcel'
export const placementcompanyUploadShortlist = serverAddress + 'placement/uploadshortlist/'
export const placementcompanyUploadWaitlistExcel = serverAddress + 'placement/uploadwaitlist/'
export const placementcompanyGetInterviewListExcel = serverAddress + 'placement/interviewsheet/'
export const placementcompanyUploadSelectedExcel = serverAddress + 'placement/uploadselected/'
export const placementcompanyDownloadPlacedExcel = serverAddress + 'placement/placedexcel/'
export const placementInterviewState = serverAddress + 'placement/interviewstate'

export const studentLoginAddress = serverAddress + 'studentauth/login'
export const studentLogoutAddress = serverAddress + 'studentauth/logout'
export const studentRefreshTokenAddress = serverAddress + 'studentauth/token'
export const studentValidateAccessTokenAddress = serverAddress + 'studentauth/jwttest'
export const studentGetData = serverAddress + 'student'
export const studentUpdateBasicData = serverAddress + 'student/updatebasic'
export const studentListAllCompanies = serverAddress + 'student/companydetails'
export const studentUploadFiles = serverAddress + 'student/upload'
export const studentUploadCheckFiles = serverAddress + 'student/uploadcheck'
export const studentGetFileUrl = serverAddress + 'student/downloadurl'
export const studentDownloadFile = serverAddress + 'student/download'
export const studentProfilePicBase64 = serverAddress + 'student/images/'
export const studentGetCompanyDetails = serverAddress + 'student/company'
export const studentMasterCV = serverAddress + 'student/mastercv'
export const studentMasterCVforVerification = serverAddress + 'student/mastercvforverification'
export const studentCVName = serverAddress + 'student/cvname'
export const studentGetCV = serverAddress + 'student/getcv'
export const studentCreateCV = serverAddress + 'student/newcv'
export const studentUpdateCV = serverAddress + 'student/updatecv'
export const studentDeleteCV = serverAddress + 'student/deletecv'
export const studentListAllListings = serverAddress + 'student/companylistings'
export const studentGetNotifications = serverAddress + 'student/notifications'
export const studentNotificationMarkAsRead = serverAddress + 'student/notificationcheck'
export const studentNotificationMarkAsUnread = serverAddress + 'student/notificationunread'
export const studentNotificationStatus = serverAddress + 'student/checkunreadnotification'
export const studentGetNotificationsSpecific = serverAddress + 'student/pernotifications'
export const studentNotificationMarkAsReadSpecific = serverAddress + 'student/pernotificationcheck'
export const studentNotificationMarkAsUnreadSpecific = serverAddress + 'student/pernotificationunread'
export const studentRegisterForListing = serverAddress + 'student/register'
export const studentGetAllApplications = serverAddress + 'student/applications'
export const studentGetPreferenceReference = serverAddress + 'student/reference'
export const studentGetPreferencePerSlot = serverAddress + 'student/preferences/'
export const studentSetPreferencePerSlot = serverAddress + 'student/preferences'
export const studentUnregisterListing = serverAddress + 'student/unregister'
export const studentIsItPlacement = serverAddress + 'student/placement_detail'
export const studentJobDescriptionFileDownload = serverAddress + 'student/document/'
export const studentUploadProofsDrive = serverAddress + 'student/file'

export const allEligibilityDegrees = serverAddress + 'eligibility'
// export const allEligibilityDegreesCompany = serverAddress + 'eligibility/company'
// export const eligibilityLookup = serverAddress + 'eligibility/lookup'
// export const allEligibilityDegrees = serverAddress + 'course/feild_1.json'
export const allEligibilityDegreesCompany = serverAddress + 'course/feild_1_for_company.json'
export const eligibilityLookup = serverAddress + 'course/lookup_next.json'
export const eligibilityDegreesLabels = serverAddress + 'eligibility/labels'

export const statusLookupAddress = serverAddress + 'status_lookup'

export const pdfCVMaker = serverAddress + 'pdf/student'
export const pdfCVMakerZipped = serverAddress + 'pdf/company'
export const registeredStudentsDetails = serverAddress + 'pdf/company_details'
export const shortlistedStudentsDetails = serverAddress + 'pdf/company_details_shortlist'

export const applicationStatusLookup = serverAddress + 'static/Json/application.json'
export const listingStatusLookup = serverAddress + 'static/Json/status_lookup.json'
// export const applicationStatusLookup = 'http://localhost:4000/api/static/Json/application.json'
// export const listingStatusLookup = 'http://localhost:4000/api/static/Json/status_lookup.json'
